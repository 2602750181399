import {AfterViewInit, Component} from '@angular/core';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'ioannassite';

  ngAfterViewInit(): void {
    firebase.analytics().logEvent('websiteOpened');
  }
}
