import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    firebase.analytics().logEvent('contactOpened');
  }

  constructor() {
  }

  ngOnInit() {
  }

}
