import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    firebase.analytics().logEvent('workOpened');
  }

}
