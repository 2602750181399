import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
    firebase.analytics().logEvent('aboutOpened');
  }

  constructor() {
  }

  ngOnInit() {
  }

  cvClicked() {
    firebase.analytics().logEvent('cvClicked');
  }
}
